#mobile-menu {
    @apply fixed top-0 left-full right-0 w-[min(75vw,400px)] h-screen rounded-l-3xl pt-[var(--header-height)] p-4 z-30
    bg-gray-light dark:bg-gray-darker transition-all flex flex-col;
}

#mobile-menu ul::before,
#mobile-menu ul::after {
    @apply content-none;
}

#mobile-menu li {
    @apply p-4 flex justify-start items-center;
}

#mobile-menu li a {
    @apply flex items-center;
}

#mobile-menu li a svg {
    @apply dark:text-white transition-colors duration-200 shrink-0;
}

#mobile-menu li a:hover span,
#mobile-menu li a:hover svg {
    @apply text-blue-sapphire dark:text-blue-sapphire;
}

#mobile-menu li a span {
    @apply text-xl ml-8 p-4 flex justify-center align-middle dark:text-white transition-colors duration-200;
    font-family: 'Montserrat', sans-serif;
}

#layer {
    @apply bg-white dark:bg-black fixed top-0 left-0 bottom-0 right-0 z-20 transition-opacity h-screen opacity-30;
}

#mobile-menu .toggle-container {
    @apply mt-auto mb-16;
}
