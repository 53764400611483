* {
    box-sizing: border-box;
}

:root {
    --black: #333333;
    --white: #f5f5f5;
    --transition: 0.5s ease;
}

.toggle-wrapper {
    @apply flex items-center justify-center;
}

.toggle {
    @apply focus-within:ring-2 focus-within:ring-blue-sapphire;
    height: 32px;
    width: 80px;
    background: var(--black);
    border-radius: 40px;
    padding: 6px;
    position: relative;
    cursor: pointer;
}

.toggle::before {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 30px;
    background: var(--white);
    position: absolute;
    z-index: 2;
    transform: translate(0);
    transition: transform var(--transition);
}

.toggle.enabled::before {
    transform: translateX(48px);
}

.toggle input {
    position: absolute;
    top: 0;
    opacity: 0;
}

.toggle .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 5px;
}

.toggle .icons svg {
    @apply w-6 h-6;
    fill: var(--white);
    z-index: 0;
}
